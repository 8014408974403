*{
  font-family: Beiruti;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.student-pass{
  direction: ltr;
  font-size: 18pt;
  font-weight: 900;
}

.App-header {
  background-color: #e2e2e2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #202020;
}

.App-link {
  color: #61dafb;
}

.description {
  color: #202020;
  font-weight: 500;
}

.input-field{
  padding: 10px;
  border-radius: 10px;
  border-color: green;
  border: none;
  font-size: 14pt;
}

.submit-button{
  margin-top: 20px;
  background-color: green;
  color: white;
  font-weight: 700;
  font-size: 14pt;
  width: 150px;
  height: 45px;
  border-radius: 10px;
  padding: 5px;
}

.input-field:focus{
  border: none;
}

.submit-button:hover{
  background-color: brown;
}
.student-info {
  direction: rtl;
  color: #202020;
  font-size: 16pt;
  font-weight: 600;

}

.k9 {
  margin: 10px;
  padding: 10px;
  color: white;
  background-color: red;
  font-size: 16pt;
  font-weight: 600;
  border-radius: 10px;
  padding: 5px;
  border: none;
}
.moodle {
  margin: 10px;
  padding: 10px;
  color: white;
  background-color: brown;
  font-size: 16pt;
  font-weight: 600;
  border-radius: 10px;
  padding: 5px;
  border: none;
}

.buttons  {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

a {
  text-decoration: none;
  color: white;
  padding: 10px;
}


.App-intro{
  direction: rtl;
  padding: 20px;
text-align: justify;
}